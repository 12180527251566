span.icon-refresh {
    right: 60px;
    position: absolute;
}
span.icon-refresh img {
    width: 25px;
}

.dropdown .dropdown-menu.show {
    animation-name: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.simplebar-wrapper {
    position: fixed;
}

.sidebar-nav {
    overflow: auto;
}

/* INICIO Scrollbar */

/* body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */

/* width */
.sidebar-nav::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sidebar-nav::-webkit-scrollbar-track {
  background: #222e3c;
}

/* Handle */
.sidebar-nav::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.sidebar-nav::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* FIN Scrollbar */

/*.table-responsive {
    width: 100%;
}*/

th {
    user-select: none;
}

th.sort-asc, th.sort-desc {
    position: relative;
    cursor: pointer;
    padding-right: 30px;
}

th.sort-asc:after {
    font-family: bootstrap-icons;
    content: "\F57B";
    right: 8px;
    position: absolute;
}

th.sort-desc:after {
    font-family: bootstrap-icons;
    content: "\F575";
    right: 8px;
    position: absolute;
}

.page-item .page-link {
    user-select: none;
}

@media only screen and (max-width: 575px) {
  span.icon-refresh {
        right: 35px;
        position: absolute;
    }
}

div.loader {
    width: 100%;
    height: 100%;
    background-color: #adb5bd;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-switch .form-check-input {
    width: 3em; height: 1.5em;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

#notfound {
    position: relative;
    height: 100vh
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    padding: 0 15px
}

.notfound .notfound-404 {
    position: relative;
    height: 150px;
    line-height: 150px;
    margin-bottom: 60px
}

.notfound .notfound-404 h1 {
    font-family: titillium web,sans-serif;
    font-size: 186px;
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
    background: url(../img/error-background.jpg);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: center
}

.notfound h2 {
    font-family: titillium web,sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin: 0
}

.notfound p {
    font-family: montserrat,sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase
}

.notfound a {
    font-family: titillium web,sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border: none;
    background: #5c91fe;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 1px;
    margin-top: 15px;
    -webkit-transition: .2s all;
    transition: .2s all
}

.notfound a:hover {
    opacity: .8
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
        height:110px;
        line-height: 110px
    }

    .notfound .notfound-404 h1 {
        font-size: 120px
    }
}

input.react-datepicker-time__input {
    border: 1px solid #aeaeae;
    border-radius: 4px;
    box-shadow: inset 0 2px 2px #e9e9e9;
    font-size: 13px;
    line-height: 16px;
    padding: 6px 10px 5px; 
}

/* INICIO Blur Text */

.blur-text {
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    user-select: none;
}

.user-select-text {
    user-select: text !important;
}

.break-word {
    /*white-space: pre-wrap;*/      /* CSS3 */   
    /*white-space: -moz-pre-wrap;*/ /* Firefox */    
    /*white-space: -pre-wrap;*/     /* Opera <7 */   
    /*white-space: -o-pre-wrap;*/   /* Opera 7 */    
    white-space: normal;
    word-wrap: break-word;      /* IE */
}

.user-select-none {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* FIN Blur Text */

.word-break-all {
    word-break: break-all !important;
}

.table-responsive {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

label.required:after {
    content: " *";
    color: red
}

/* INICIO Table Width Fixed */

@media screen and (min-width: 769px) 
{
    .table-api-responsive 
    {
        table-layout: fixed !important;
        word-wrap: break-word;
        width: 100% !important;
    }

    .table-api-responsive thead tr th:first-child, .table-api-responsive tbody tr td:first-child 
    {
        width: 60% !important;
    }

}

@media screen and (max-width: 768px) 
{
    .table-api-responsive 
    {
        table-layout: fixed !important;
        word-wrap: break-word;
    }

}

/* FIN Table Width Fixed */

.vanilla-jsoneditor-react {
  display: flex;
  flex: 1;
}

.vanilla-jsoneditor-react .jse-menu .jse-group-button, .vanilla-jsoneditor-react .jse-button.jse-transform,  .vanilla-jsoneditor-react .jse-button.jse-search{
    display: none;
}

.minus-sign::after {
    content: '-';
}

.container-fluid .card.border.border-primary, .container-fluid .card.border.border-warning, .container-fluid .card.border.border-dark, 
.container-fluid .card.border.border-danger, .container-fluid .card.border.border-success, .container-fluid .card.border.border-light {
    border-width: 2px 2px 2px 2px !important;
}
